












































































































































































































































































































































import Vue, { PropType } from "vue";

import {
  Business,
  CartMembership,
  CartProduct,
  CartService,
  CartServicePackage,
  Role,
} from "@/types";
import Quantity from "@/components/fragments/QuantityInput.vue";
import employeeStoreModule from "@/store/modules/employee";
import { createNamespacedHelpers } from "vuex";

type ProductType =
  | "product"
  | "service"
  | "service-package"
  | "membership"
  | "voucher";

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("CART_EMPLOYEES");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend({
  name: "CartItem",
  components: { Quantity },
  props: {
    item: {
      type: Object as PropType<any>,
      required: true,
    },
    product_type: {
      type: String as PropType<ProductType>,
      required: true,
    },
  },
  data: () => ({
    apiUrl: VUE_APP_API_URL,
    menu: false,
    qty: 1,
    unitPrice: 0,
    appointmentDate: "",
    appointmentTime: "",
    staff: "",
  }),
  computed: {
    ...employeeGetters(["employeePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    products: function (): CartProduct[] {
      return this.$store.getters["cart/products"];
    },
    cart_services: function (): CartService[] {
      return this.$store.getters["cart/services"];
    },
    cart_memberships: function (): CartMembership[] {
      return this.$store.getters["cart/memberships"];
    },
    cartServicePackages: function (): CartServicePackage[] {
      return this.$store.getters["cart/servicePackages"];
    },
  },
  created() {
    this.qty = this.item.quantity;
    this.unitPrice = this.item.unitPrice;
    this.appointmentDate = this.item.appointmentDate;
    this.appointmentTime = this.item.appointmentTime;
    this.fetchEmployeeList();
  },
  watch: {
    appointmentTime() {
      this.updateItem();
      this.fetchEmployeeList();
    },
    appointmentDate() {
      this.updateItem();
      this.fetchEmployeeList();
    },
    staff() {
      this.updateItem();
    },
    qty() {
      this.updateItem();
    },
    unitPrice() {
      if (this.unitPrice > 0) {
        this.updateItem();
      }
    },
  },
  methods: {
    ...employeeActions(["getWithAvailability"]),
    updateItem() {
      const item: any = {
        ...this.item,
        unitPrice: this.unitPrice,
        quantity: this.qty,
      };

      if (
        this.product_type === "service" ||
        this.product_type === "service-package"
      ) {
        item.appointmentDate = this.appointmentDate;
        item.appointmentTime = this.appointmentTime;
        item.staff = this.staff;
      }
      this.$store.dispatch("cart/updateCartItem", {
        item,
        itemType: this.product_type,
      });
    },
    getImageUrl(image: any) {
      if (image) {
        return `${this.apiUrl}/v1/file/${image.filename}`;
      }
      return "../../assets/placeholder.png";
    },
    fetchEmployeeList() {
      if (!this.appointmentDate || !this.appointmentTime) return;
      const bid = (this.role.business as Business)?._id;
      const date = `${this.appointmentDate} ${this.appointmentTime}`;
      const duration = this.item.service?.advancedPricing?.length
        ? this.item.service.advancedPricing[0].durationInMinutes
        : 30;
      let params = `?businessId=${bid}&durationInMinutes=${duration}&startDate=${date}`;
      if (this.item.services)
        params += `&serviceIds=${this.item.services.join("&serviceIds=")}`;
      else if (this.item.service)
        params += `&serviceIds=${this.item.service._id}`;
      this.getWithAvailability(params);
    },
    getRules(field: string) {
      return [(v: string) => !!v || `${field} is required`];
    },
    onlyDeposit() {
      //code
    },
    removeItem() {
      this.$store.dispatch("cart/updateCartItem", {
        item: {
          ...this.item,
          unitPrice: this.unitPrice,
          quantity: 0,
        },
        itemType: this.product_type,
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CART_EMPLOYEES")) {
      this.$store.registerModule("CART_EMPLOYEES", employeeStoreModule);
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule("CART_EMPLOYEES");
  },
});
